import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import OTPBox from "../../../components/OTPBox/OTPBox";
import { useEffect, useState } from "react";
import { AuthService } from "../../../services/auth.service";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import Countdown from "../../../components/CountDown/Countdown";
import { replaceExceptLastTwo } from "../../../helpers/common.helper";

const OTPSection = () => {

    const [otpval, setOtpval] = useState('');
    const [otpInvalid, setOtpInvalid] = useState(true);
    const navigate = useNavigate();
    const idValue = useSelector(state => state.userData.id);
    const userSlice = useSelector(state => state.userData);

    const [isResendDisabled, setIsResendDisabled] = useState(true);

    const { mutate: verifyOtpMutation } = useMutation(AuthService.verifyOTP, {
        onSuccess: (data) => {
            localStorage.setItem('token', data.data.data);
            if (userSlice.quickBook === true) {
                navigate('/login/choose-car')
            } else {
                navigate('/login/password')
            }
        },
        onError: (error) => {
            console.log(error)
        }
    });

    const { mutate: resendOtpMutation } = useMutation(AuthService.resendOTP, {
        onSuccess: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error)
        }
    });

    const countdownComplete = () => {
        setIsResendDisabled(false)
    }

    const handleResendClick = () => {
        // Add your logic here to resend the OTP
        resendOtpMutation(idValue)
        setIsResendDisabled(true)
    }

    const verifyOTPHandler = () => {
        const formData = { id: idValue, otp: otpval }; // Capture the otpval in a variable
        verifyOtpMutation(formData);
    }

    const handleChange = (otp) => {
        setOtpval(otp)
        if (otp.length === 4) {
            setOtpInvalid(false)
        } else {
            setOtpInvalid(true)
        }
    }

    const maskedPhone = (userSlice.phone !== undefined || userSlice.phone !== "") ? replaceExceptLastTwo(userSlice.phone) : ""

    return (
        <div>
            <h3>Enter OTP</h3>
            <p>
                Please enter the One-Time Password to verify your account
            </p>
            {(maskedPhone !== "") ?
                <p>An OTP has been sent to {maskedPhone}</p>
                : ""}
            <OTPBox otp={handleChange} />
            <div>
                <p className="resend">
                Didn't receive the code ? <span
                        className={isResendDisabled ? 'disabled' : 'request'}
                        onClick={isResendDisabled ? null : handleResendClick}
                    >Request Again</span> {isResendDisabled && (
                        <span className="countdown-timer"><Countdown initialTime={60} onComplete={countdownComplete} /></span>
                    )}
                </p>
            </div>
            <Button onClick={verifyOTPHandler} disabled={otpInvalid} text="Verify OTP" />
        </div>
    )
}

export default OTPSection;
