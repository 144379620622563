// Import createSlice from Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';

// Define a userSlice using createSlice
export const userSlice = createSlice({
    name: 'userData', // Name of the slice
    initialState: {
        // Initial state object with various properties
        name: '',
        email: '',
        phone: '',
        id: '',
        cars: [],
        selectedLicense: '',
        selectedAppointmentId: '',
        selectedPaymentMethod: '',
        appointmentIncadeaId: '',
        vouchercode: '',
        voucherAmt: 0,
        quickBook: false,
        passwordResetFlow: false,
        dayVouchers: {
            Sunday: "",
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: "",
            Saturday: ""
        }
    },
    reducers: {
        // Reducer to set user data
        setUserData: (state, action) => {
            // Destructure the payload and update the state accordingly
            const { name, email, phoneNumber, _id } = action.payload;
            return {
                ...state,
                name: (name == undefined) ? "" : name,
                email: (email == undefined) ? "" : email,
                phone: (phoneNumber == undefined) ? "" : phoneNumber,
                id: _id,
                cars: [],
                selectedLicense: '',
                selectedAppointmentId: '',
                selectedPaymentMethod: '',
                appointmentIncadeaId: ''
            }
        },
        // Reducer to add a single car to the state
        addCar: (state, action) => {
            const car = action.payload;
            state.cars.push(car);
        },
        // Reducer to replace the cars array with a new array
        addCars: (state, action) => {
            const car = action.payload;
            state.cars = car;
        },
        // Reducer to set the selected license
        setSelectedLicense: (state, action) => {
            const license = action.payload;
            state.selectedLicense = license;
        },
        setDayVouchers: (state, action) => {
            const payl = action.payload;
            console.log(payl)
            state.dayVouchers = payl;
        },
        // Reducer to set voucher information
        setVoucher: (state, action) => {
            state.vouchercode = action.payload.vouchercode;
            state.voucherAmt = action.payload.voucheramt;
        },
        // Reducer to set the selected appointment ID
        setSelectedAppointmentId: (state, action) => {
            const appointmentId = action.payload;
            state.selectedAppointmentId = appointmentId;
        },
        // Reducer to set the Incadea appointment ID
        setIncadeaAppointmentId: (state, action) => {
            const appointmentId = action.payload;
            state.appointmentIncadeaId = appointmentId;
        },
        // Reducer to set the selected payment method
        setSelectedPaymentMethod: (state, action) => {
            state.selectedPaymentMethod = action.payload;
        },
        // Reducer to set the Quick Book status
        setQuickBook: (state, action) => {
            const qb = action.payload;
            state.quickBook = qb;
        },
        setPasswordResetFlow: (state, action) => {
            const qb = action.payload;
            state.passwordResetFlow = qb;
        },
        // Reducer to update name and email
        updateEmailName: (state, action) => {
            const { name, email } = action.payload;
            return {
                ...state,
                name: name,
                email: email
            }
        },
        // Reducer to log out and reset the state
        logout: (state) => {
            return {
                ...state,
                cars: [],
                name: '',
                email: '',
                phone: '',
                id: '',
                selectedLicense: '',
                selectedAppointmentId: '',
                selectedPaymentMethod: '',
                appointmentIncadeaId: '',
                vouchercode: '',
                voucherAmt: 0,
                quickBook: false,
                passwordResetFlow: false,
                dayVouchers: {}
            };
        },
    }
});

// Export individual action creators for use in other parts of the application
export const {
    setUserData,
    addCar,
    addCars,
    setSelectedLicense,
    setQuickBook,
    setSelectedAppointmentId,
    setSelectedPaymentMethod,
    setPasswordResetFlow,
    setVoucher,
    updateEmailName,
    setIncadeaAppointmentId,
    logout,
    setDayVouchers
} = userSlice.actions;

// Export the reducer function to be used in the Redux store
export default userSlice.reducer;
