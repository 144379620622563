// This function converts an XML structure into a JavaScript object containing date and time information.

function dateTimeJSON(xml) {
    console.log(xml)
    // Extract the parent element that contains date and time data from the XML
    const dateTimeParent = xml.children[0].children[0].children[1].children;

    // Initialize an empty object to store the final result
    let finalObj = {};
    
    // Iterate over the children of dateTimeParent
    console.log(dateTimeParent)
    dateTimeParent.map((value, index) => {
        // Extract the date value from the XML
        const dateA = value.children[0].value;

        // Check if the date already exists in finalObj
        if (finalObj[dateA]) {
            // If the date exists, create an object with startDate and endDate
            let obj = {
                startDate: value.children[1].value,
                endDate: value.children[2].value
            };

            // Add the object to the date's array in finalObj
            finalObj[dateA].push(obj);
        } else {
            // If the date does not exist, create an empty array for it
            finalObj[dateA] = [];
        }
    });
    // Return the JavaScript object with date and time data
    if(Object.keys(finalObj).length==1 && Object.keys(finalObj)[0]==""){
        finalObj={};
    }
    return finalObj;
}

// Export the dateTimeJSON function for use in other parts of the application
export default dateTimeJSON;
