
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './payment.scss';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { UserService } from '../../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { setQuickBook, setSelectedAppointmentId, setSelectedLicense, setVoucher } from '../../features/login/login.slice';
import { CircularProgress, Modal, ModalDialog } from '@mui/joy';

import check from '../../img/check.png';
import { clearServices } from '../../features/service/service.slice';

const PaymentPage = () => {
    // Import necessary hooks and functions
    const navigate = useNavigate(); // Hook for navigation
    const dispatch = useDispatch(); // Hook for Redux dispatch

    const [showProgress, setShowProgress] = useState(true);
    const [statusResp, setStatusResp] = useState("");

    // UseSearchParams hook to get search parameters from the URL
    let [searchParams, setSearchParams] = useSearchParams();

    // Get the 'paymentId' parameter from the URL
    const paymentID = searchParams.get('paymentId');

    // Retrieve user data from the Redux store using useSelector
    const userSlice = useSelector(state => state.userData);
    var incadeaId = userSlice.appointmentIncadeaId;
    incadeaId = incadeaId.replace("Success-", "");

    // Destructure values from the useMutation hook for reference update
    const { mutate: referenceUpdateMutation } = useMutation(UserService.updateReference, {
        onSuccess: (data) => {
            // On successful reference update, perform actions and reset state
            setShowProgress(false);
            setStatusResp("success")
            dispatch(setSelectedAppointmentId(""));
            dispatch(setSelectedLicense(""));
            dispatch(clearServices())
            dispatch(setVoucher({
                vouchercode: "",
                voucheramt: ""
            }));
            dispatch(setQuickBook(false));
        },
        onError: (error) => {
            // Handle reference update error
            console.log(error);
            setStatusResp("failed")
            setShowProgress(false);
            // dispatch(clearServices())
        }
    });

    // Use the useEffect hook to perform actions when the component mounts
    useEffect(() => {
        // Check if appointmentIncadeaId and paymentID are available
        if ((userSlice.appointmentIncadeaId !== "" || userSlice.appointmentIncadeaId !== undefined) &&
            (paymentID !== undefined || paymentID !== "")) {
            // Create an object with relevant data
            const obj = {
                paymentId: paymentID,
                appointmentId: userSlice.selectedAppointmentId,
                voucherCode: userSlice.vouchercode,
                voucherAmt: userSlice.voucherAmt
            }
            // Initiate the reference update mutation with the object
            referenceUpdateMutation(obj)
        }
        document.title = 'AUTOLAB Qatar | Payment';
    }, []) // The empty dependency array means this effect runs once when the component mounts


    return (
        <Modal
            open={true}
        >
            <ModalDialog>
                <div className='payment'>
                    {(showProgress) ?
                        <CircularProgress
                            color="primary"
                            determinate={false}
                            size="md"
                            variant="soft"
                        />
                        :
                        <div>
                            {(statusResp === "success") ?
                                <div>
                                    <h2>Payment successfully completed</h2>
                                    <img src={check} alt='car' />
                                    <p>
                                        Your payment reference number is: {paymentID} <br /> booking id is {incadeaId}
                                    </p>
                                    <Button variant={'border'} text='Back to Dashboard' disabled={false} onClick={(e) => navigate('/login/choose-car')} />
                                </div>
                                : ""}
                            {(statusResp === "failed") ?
                                <div>
                                    <h2>Payment reference update failed</h2>
                                    <p>
                                        Your payment reference number is: {paymentID} <br /> booking id is {incadeaId}. please keep this with you
                                    </p>
                                    <Button variant={'border'} text='Back to Dashboard' disabled={false} onClick={(e) => navigate('/login/choose-car')} />
                                </div>
                                : ""}
                        </div>
                    }
                </div>
            </ModalDialog>
        </Modal>

    )
}

export default PaymentPage;