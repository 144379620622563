import { Modal, ModalDialog } from "@mui/joy";
import InputBox from "../../../../components/InputBox/InputBox";
import Button from "../../../../components/Button/Button";
import { useMutation } from "react-query";
import { UserService } from "../../../../services/user.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import XMLParser from 'react-xml-parser';
import { useState } from "react";

import licenseImg from './../../../../img/license_plate_qatar.png';
import { addCar, setSelectedLicense } from "../../../../features/login/login.slice";

const AddLicenseModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showDialogError, setShowDialogError] = useState(false);
    const [dialogBtnDisabled, setDialogBtnDisabled] = useState(true);
    const [licenseNumber, setLicenseNumber] = useState("");

    const { mutate: searchCarMutation } = useMutation(UserService.licenseSearch, {
        onSuccess: (data) => {
            var xml = new XMLParser().parseFromString(data.data);
            const largeDoc = xml.children[0].children[0].children[1].children[0].children;
            const makeCode = largeDoc.find(obj => obj.name === "MakeCode").value;
            const vin = largeDoc.find(obj => obj.name === "VING").value;
            const modelg = largeDoc.find(obj => obj.name === "ModelG").value;
            const myear = largeDoc.find(obj => obj.name === "ModelyearG").value;

            if (makeCode !== "") {
                var obj = {
                    vehicleNumber: licenseNumber,
                    model: modelg,
                    year: myear,
                    vin: vin,
                    carBrand: makeCode,
                    temp: true
                }
                dispatch(addCar(obj))
            }
            dispatch(setSelectedLicense(licenseNumber))
            navigate('/service-request/car');
        },
        onError: (error) => {
            console.log(error);
            dispatch(setSelectedLicense(licenseNumber))
            navigate('/service-request/car');
        }
    });

    const licenseChangeHandler = (e) => {
        const sanitizedValue = e.replace(/[^0-9]/g, '');
        setLicenseNumber(sanitizedValue);
        setShowDialogError(false);
        if (e !== "" && e.length > 1 && e.length < 7) {
            setDialogBtnDisabled(false)
        } else {
            setDialogBtnDisabled(true)
        }
    }

    const saveLicense = () => {
        const checkData = props.userData.data.myCars.find(obj => obj.vehicleNumber === licenseNumber)
        if (checkData === undefined) {
            const formData = {
                license: licenseNumber
            }
            searchCarMutation(formData);

        } else {
            // throw error
            setShowDialogError(true)
        }
    }

    const dialogClose = () => {
        setLicenseNumber("");
        props.dialogClose()

    }

    const licensekeyDown = (e) => {
        if (e.key === 'Enter' && !dialogBtnDisabled) {
            saveLicense();
        }
    }
    return (
        <Modal
            open={props.dialogOpen}
            onClose={dialogClose}
        >
            <ModalDialog>
                <div className="dialog-content">
                    <h3>Enter your license number</h3>
                    <img src={licenseImg} alt="car" className="licenseImg" />
                    <InputBox type={"number"}
                        placeholder="Enter your license number" value={licenseNumber} label="license number" onChange={licenseChangeHandler}
                        onKeyDown={licensekeyDown}
                    />
                    {(showDialogError) ? <span className="error">License already exists</span> : ""}
                    <div className="button-cont">
                        <Button text='Cancel' variant={'border'} onClick={dialogClose} />
                        <Button text='Continue' onClick={saveLicense} disabled={dialogBtnDisabled} />
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default AddLicenseModal;