import { Modal, ModalDialog } from "@mui/joy";

import check from '../../../../../img/check.png';
import Button from "../../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const BookingModal = (props) => {
    const navigate = useNavigate();
    return (
        <Modal
            open={props.successDialogOpen}
        >
            <ModalDialog>
                <div className="dialog-content completed-dialog">
                    <h2>Booking completed</h2>
                    <img src={check} alt='gt' />
                    <p>
                        Thank you for your booking. Your booking Id is: {props.incadeaBookingID}
                    </p>
                    <Button text='Back to Dashboard' variant={'border'} onClick={(e) => navigate('/login/choose-car')} />
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default BookingModal;