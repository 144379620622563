import { useEffect, useState } from "react";
import { UserService } from "../../../services/user.service";
import { useQuery, useQueryClient } from "react-query";

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from "react-router-dom";
import { addCars, setSelectedLicense, setUserData } from "../../../features/login/login.slice";
import { useDispatch } from "react-redux";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import './chooseCar.scss';


import DeleteCarModal from "./components/DeleteCarModal";
import AddLicenseModal from "./components/AddLicenseModal";

const ChooseCar = () => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);



    const [pageLoaded, setPageLoaded] = useState(false);

    const [deleteId, setDeleteId] = useState("");

    // Get QueryClient from the context
    const queryClient = useQueryClient()

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { isLoading, data: userData, error: userError } = useQuery('getUser', UserService.getUser, {
        keepPreviousData: false,
        refetchOnMount: 'always',
        cacheTime: 0
    });
    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['getUser'] })
        document.title = 'AUTOLAB Qatar | Choose car';
    }, [])

    useEffect(() => {
        if (isLoading === false && userData !== undefined) {
            dispatch(setSelectedLicense(""))
            dispatch(setUserData(userData.data));
            dispatch(addCars(userData.data.myCars))
            setPageLoaded(true)
        }
    }, [isLoading, userData])

    useEffect(() => {
        if (userError) {
            if (userError.response !== undefined) {
                if (userError.response.status === 403) {
                    navigate("/logout");
                }
            } else {
                navigate("/logout");
            }
        }
    }, [userError])


    const goToAddNew = () => {
        setDialogOpen(true)
    }
    const dialogClose = () => {
        setDialogOpen(false)
    }

    const existingCar = (license) => {
        dispatch(setSelectedLicense(license));
        navigate('/service-request/car');
    }

    const deleteCar = (id) => {
        setDeleteId(id);
        setDeleteDialogOpen(true);
    }

    const deleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setDeleteId("");
    }

    if (pageLoaded === false) {
        return "Loading";
    }
    return (
        <div>
            <h3>Add / Select Your Car</h3>
            <p>
               
            </p>
            <div className="choose_cont">
                {userData && userData.data.myCars.map((data, index) => {
                    return (
                        <div key={index} className="individual-car">
                            <div className="car-left" onClick={() => existingCar(data.vehicleNumber)}>
                                <DirectionsCarIcon />
                            </div>
                            <div className="car-right" onClick={() => existingCar(data.vehicleNumber)}>
                                <span className="license">{data.vehicleNumber}</span>
                                <span className="model">{data.model}</span>
                            </div>
                            <span className="delete-icon" onClick={(e) => deleteCar(data._id)}><RemoveCircleIcon /></span>
                        </div>
                    )
                })}
                <div key={999} className="individual-car" onClick={goToAddNew}>
                    <div className="car-left">
                        <AddIcon />
                    </div>
                    <div className="car-right">
                        <span className="license">Add New Car</span>
                        <span className="model">Add a car to your account</span>
                    </div>
                </div>
            </div>

            <AddLicenseModal dialogOpen={dialogOpen} userData={userData} dialogClose={dialogClose} />

            <DeleteCarModal deleteDialogClose={deleteDialogClose} deleteId={deleteId} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />


        </div>
    )
}

export default ChooseCar;