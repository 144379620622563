import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import InputBox from "../../../components/InputBox/InputBox";
import { AuthService } from "../../../services/auth.service";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from "react-redux";
import { setPasswordResetFlow } from "../../../features/login/login.slice";

const ChoosePassword = () => {
    const userSlice = useSelector(state => state.userData);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isButtonEnabled = password === confirmPassword && password.length >= 8; // Enable button if passwords match and are at least 6 characters

    const { mutate: createPasswordMutation, error: createPasswordError } = useMutation(AuthService.setPassword, {
        onSuccess: (data) => {
            dispatch(setPasswordResetFlow(false));
            navigate('/login/choose-car')
        },
        onError: () => {
            console.log('error')
        }
    });

    const handlePasswordChange = (e) => {
        setError("")
        setPassword(e);
    };

    useEffect(() => {
        if (createPasswordError) {
            setError(createPasswordError.response.data.errors[0].msg)
        }
    }, [createPasswordError])


    const handleConfirmPasswordChange = (e) => {
        setError("")
        setConfirmPassword(e);
    };

    const handleSignUp = () => {
        if (isButtonEnabled) {
            // Perform sign-up logic here
            createPasswordMutation({ password, confirmPassword })
        }
    };

    return (
        <div>
            <h3>Choose Password</h3>
            <p>
                Please enter the Password for your account
            </p>
            <InputBox placeholder="Enter your password" label="Password" type="password" value={password} onChange={handlePasswordChange} />
            <PasswordStrengthBar password={password} />
            <InputBox placeholder="confirm your password" label="Confirm Password" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
            {(error !== "") ? <span className="error">{error}</span> : ""}
            <Button text={(userSlice.passwordResetFlow === false) ? "Sign up" : "Reset password"} onClick={handleSignUp} disabled={!isButtonEnabled} />
        </div>
    )
}

export default ChoosePassword;