import Button from "../../../../components/Button/Button";
import InputLabel from '@mui/material/InputLabel';
import { TextField } from "@mui/material";
import validator from "validator";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import './car-details.scss';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { UserService } from "../../../../services/user.service";
import { addCars, setQuickBook, updateEmailName } from "../../../../features/login/login.slice";
import EngineSizeComp from "./engineSize";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CarOTPModal from "./components/CarOTPModal";
const CarDetails = () => {

    const userSlice = useSelector(state => state.userData);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [carModels, setCarModels] = useState([]);
    const [carBrands, setCarBrands] = useState([]);

    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [errors, setErrors] = useState({});

    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [showGeelyPopup, setShowGeelyPopup] = useState(false);

    const [formData, setFormData] = useState({
        vehicleNumber: userSlice.selectedLicense,
        carBrand: "",
        carModel: "",
        engineSize: "",
        year: "",
        fuelType: "",
        engineOrigin: "",
        vin: "",
        name: userSlice.name,
        email: userSlice.email,
        temp: false
    });

    const navigate = useNavigate();

    const { isLoading: dropLoading, data: dropdownData } = useQuery('getDropDown', UserService.getBrands);
    const { mutate: addCarsMutation } = useMutation(UserService.addCars, {
        onSuccess: (data) => {
            dispatch(addCars(data.data.myCars));
            if (userSlice.name === "" || userSlice.name === undefined) {
                dispatch(updateEmailName({
                    name: formData.name,
                    email: formData.email
                }));
            }
            dispatch(setQuickBook(false));
            navigate('/service-request/service')
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const { mutate: sendOTPMutation } = useMutation(UserService.sendOTP, {
        onSuccess: (data) => {
            setDialogOpen(true);
        },
        onError: (error) => {
            setNextBtnDisabled(false)
            console.log(error);
        }
    });



    useEffect(() => {
        if (userSlice.selectedLicense !== "" && dropLoading === false) {
            setIsLoading(false)
            const selectedLicense = userSlice.selectedLicense;
            const existingCarObj = userSlice.cars.find((val) => val.vehicleNumber === selectedLicense);

            if (existingCarObj !== undefined) {
                // setIsFormDisabled(true)

                setCarModels([{ carModel: existingCarObj.model }])
                const obj = {
                    vehicleNumber: userSlice.selectedLicense,
                    carBrand: existingCarObj.carBrand,
                    carModel: existingCarObj.model,
                    year: (existingCarObj.year == null) ? "" : existingCarObj.year + "",
                    fuelType: existingCarObj.fuelType,
                    engineSize: existingCarObj.engineSize,
                    engineOrigin: existingCarObj.engineOrigin,
                    temp: (existingCarObj.temp === undefined) ? false : existingCarObj.temp,
                    name: userSlice.name,
                    email: userSlice.email,
                    vin: existingCarObj.vin
                }
                setCarBrands(dropdownData.data.brands)
                console.log('',dropdownData.data.brands)

                setFormData(obj);
                if (existingCarObj.carBrand.toLowerCase().includes("geely") || existingCarObj.model.toLowerCase().includes("geely")) {
                    setShowGeelyPopup(true);
                }
            } else {
                setCarBrands(dropdownData.data.brands)
            }
        }
    }, [dropLoading]);

    const carBrandChange = (e, newValue) => {
       
        if (newValue) {
            if (newValue.toLowerCase().includes("geely") ) {
                setShowGeelyPopup(true);
            }
            const selectedbrand = newValue;
            const models = dropdownData.data.brands.find(obj => obj.brand === selectedbrand)
            setCarModels(models.carModels);
            handleChange("carBrand", newValue)
        }
    }

    const validateField = (name, value) => {
        let error = "";
        if (name === "name" && validator.isEmpty(value)) {
            error = "Name cannot be empty";
        } else if (name === "email" && !validator.isEmail(value)) {
            error = "Invalid Email";
        } else if (name === "phone" && !validator.isMobilePhone(value)) {
            error = "Invalid Phone number";
        }
        return error;
    };

    const handleChange = (name, value) => {
        const error = validateField(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setFormData((prevState) => ({ ...prevState, [name]: value }));

    };



    useEffect(() => {
        const isFormValid = Object.keys(formData).every((key) => {
            const value = formData[key];
            if (value === null || value === undefined) {
                return false;
            }
            if (Array.isArray(value)) {
                return value.length > 0; // Check if the array is not empty
            } else if (typeof value === 'object' && Object.keys(value).length > 0) {
                return true;
            } else if (key === "email") {
                const valueA = value + "";
                return validator.isEmail(valueA);
            } else if (key === "vin") {
                return true;
            } else {
                const valueA = value + "";
                return !validator.isEmpty(valueA); // Check if non-array values are not empty
            }
        });
        if (isFormValid) {
            setNextBtnDisabled(false);
        } else {
            setNextBtnDisabled(true)
        }
    }, [formData])

    const backButtonHandler = () => {
        navigate('/login/choose-car')
    }

    const nextButtonHandler = () => {
console.log(formData.carBrand.toLowerCase(),  "Geely".toLowerCase())
        if (formData.carBrand.toLowerCase().includes("geely") || formData.carModel.toLowerCase().includes("geely")) {
            setShowGeelyPopup(true);
        }else{
  // call endpoint to send otp
  if (userSlice.quickBook === true) {
    if (isFormDisabled === true && formData.temp === false) {
        navigate('/service-request/service')
    } else {
        addCarsMutation(formData)
    }
} else {
    setNextBtnDisabled(true)
    sendOTPMutation();
}
        }
      

    }

    useEffect(() => {
        if (userSlice.selectedLicense === "" || userSlice.selectedLicense === undefined) {
            navigate("/login/choose-car");
        }
        document.title = 'AUTOLAB Qatar | Car details';
    }, []);

    const yearArray = [2025,2024,2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010,2009,2008,2007,2006];
    const backMobilePress = () => {
        navigate("/login/choose-car")
    }
    if (isLoading)
        return "Loading";
    console.log(carModels)
    return (
        <div>
            <div className="mobile-navig">
                <div className="icon-div" onClick={backMobilePress}><ArrowBackIosIcon /></div>
                <div className="heading">Car details</div>
            </div>
            <div className="car-details card">
                <h4>Enter your car details</h4>
                <div className="discount-info">
    <span>
Please ensure all the data is accurate before proceeding! </span>

</div>
                <div className="row-inputs">
                    <div className="column-inputs">
                        <InputLabel id="">Vehicle number <span className='mandatory'>*</span></InputLabel>
                        <TextField id="outlined-basic" fullWidth variant="outlined" value={userSlice.selectedLicense} disabled={true} />
                    </div>
                    <div className="column-inputs">
                        <InputLabel id="">Car brand <span className='mandatory'>*</span></InputLabel>
                        <Select
    id="demo-simple-select"
    onChange={carBrandChange}
    fullWidth
    value={formData.carBrand}
    placeholder="Select car brand"
    disabled={isFormDisabled}
>
    {(formData.temp === true) ? (
        <>
            <Option key={1} value={formData.carBrand}>{formData.carBrand}</Option>
            {carBrands.map((value, index) => (
                <Option key={index + 1} value={value.brand}>{value.brand}</Option>
            ))}
        </>
    ) : (
        carBrands.map((value, index) => (
            <Option key={index} value={value.brand}>{value.brand}</Option>
        ))
    )}
</Select>

                    </div>
                    <div className="column-inputs">
                        <InputLabel id="">Model <span className='mandatory'>*</span></InputLabel>
                        <Select
                            id="demo-simple-select"
                            fullWidth
                            placeholder="select car model"
                            defaultValue={formData.carModel}
                            onChange={(e, newValue) => handleChange("carModel", newValue)}
                            disabled={(carModels === "" || isFormDisabled || carModels.length === 0)}
                        >
                            {carModels.map((value, index) => {
                                return (
                                    <Option value={value.carModel}>{value.carModel}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="row-inputs">
                    <div>
                        <InputLabel id="">Engine size <span className='mandatory'>*</span></InputLabel>
                        <EngineSizeComp
                            data={dropdownData.data.engineSize}
                            disabled={isFormDisabled && formData.temp === false}
                            defaultValue={formData.engineSize}
                            onChange={(newValue) => handleChange("engineSize", newValue)}
                        />
                    </div>
                </div>
                <div className="row-inputs">
                    <div className="column-inputs">
                        <InputLabel id="">Year <span className='mandatory'>*</span></InputLabel>
                        <Select
                            fullWidth
                            placeholder="select year"
                            disabled={(isFormDisabled && formData.temp === false)}
                            defaultValue={formData.year}
                            onChange={(e, newValue) => handleChange("year", newValue + "")}
                        >
                            {yearArray.map((data, index) => {
                                return (
                                    <Option key={index} value={data + ""}>{data}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="column-inputs">
                        <InputLabel id="">Fuel Type <span className='mandatory'>*</span></InputLabel>
                        <Select
                            fullWidth
                            disabled={isFormDisabled && formData.temp === false}
                            defaultValue={formData.fuelType}
                            placeholder="select fuel type"
                            onChange={(e, newValue) => handleChange("fuelType", newValue)}
                        >
                            {dropdownData.data.fuelType.map((value, index) => {
                                return (
                                    <Option key={index} value={value.fuelType}>{value.fuelType}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="column-inputs">
                        <InputLabel id="demo-simple-select-label1">Engine Origin <span className='mandatory'>*</span></InputLabel>
                        <Select
                            labelId="demo-simple-select-label1"
                            placeholder="Choose engine origin"
                            label="Engine origin"
                            disabled={isFormDisabled && formData.temp === false}
                            defaultValue={formData.engineOrigin}
                            onChange={(e, newValue) => handleChange("engineOrigin", newValue)}
                            fullWidth
                        >
                            {dropdownData.data.engineOrigin.map((value, index) => {
                                return (
                                    <Option key={index} value={value.engineOrigin}>{value.engineOrigin}</Option>
                                )
                            })}

                        </Select>
                    </div>
                </div>
                <h4>Personal details</h4>
                <div className="row-inputs">
                    <div className="column-inputs">
                        <InputLabel id="">Full name <span className='mandatory'>*</span></InputLabel>
                        <TextField id="outlined-basic" fullWidth variant="outlined"
                            value={formData.name} disabled={(userSlice.name === undefined || userSlice.name === "") ? false : true}
                            onChange={(e) => handleChange("name", e.target.value)}
                            error={errors.name}
                        />
                    </div>
                    <div className="column-inputs">
                        <InputLabel id="">Mobile number <span className='mandatory'>*</span></InputLabel>
                        <TextField id="outlined-basic" fullWidth variant="outlined" value={userSlice.phone} disabled={true} />
                    </div>
                    <div className="column-inputs">
                        <InputLabel id="">Email <span className='mandatory'>*</span></InputLabel>
                        <TextField id="outlined-basic" fullWidth variant="outlined" value={formData.email}
                            disabled={(userSlice.email === undefined || userSlice.email === "") ? false : true}
                            onChange={(e) => handleChange("email", e.target.value)} error={errors.email}
                        />
                    </div>
                </div>

                <div className="button-cont">
                    <Button text="Back" variant={'border'} onClick={backButtonHandler} />
                    <Button text="Continue" onClick={nextButtonHandler} disabled={nextBtnDisabled} />
                </div>

                <CarOTPModal
                    isFormDisabled={isFormDisabled} dialogOpen={dialogOpen} formData={formData} addCarsMutation={addCarsMutation}
                    setNextBtnDisabled={setNextBtnDisabled}
                    setDialogOpen={setDialogOpen}
                />

            </div> 
            <Dialog
    open={showGeelyPopup}
    onClose={() => setShowGeelyPopup(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">
        {"Driving a Geely?                          "}
    </DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
      Book Your Service Now!
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button  text="Dismiss" variant={'border'} onClick={() => setShowGeelyPopup(false)} color="primary">
        Dismiss
        </Button>
        <Button  text="Go to Geely" onClick={() => {
            setShowGeelyPopup(false);
            // Optionally, redirect to the Geely website
            window.location.href = 'https://buy.geely.com.qa/geely_eng/service?step=1';
        }} color="primary" autoFocus>
          
        </Button>
    </DialogActions>
</Dialog>
        </div>
    )
}

export default CarDetails;

