
import { useEffect, useState } from 'react';
import BookingItem from './components/bookingItem';


const BookingOverview = (props) => {
    const [bookingItems, setBookingItems] = useState({});

    useEffect(() => {
        let serviceItems = props.services;
        let filteredItems = {};
        for (let key in serviceItems) {
            if (Array.isArray(serviceItems[key]) && serviceItems[key].length !== 0) {
                filteredItems[key] = serviceItems[key];
            }
        }
        setBookingItems(filteredItems)
    }, [props])

    return (
        <div className='bookingOverview'>
            <h3>Booking overview</h3>
            {Object.keys(bookingItems).map((value, index) => {
                return (
                    <BookingItem key={index} heading={value} value={bookingItems[value]} />
                )
            })}

        </div>
    )
}

export default BookingOverview;
