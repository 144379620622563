import { CircularProgress, Modal, ModalDialog } from "@mui/joy";
import Button from "../../../../../components/Button/Button";
import { useState } from "react";
import { setIncadeaAppointmentId, setSelectedAppointmentId, setSelectedPaymentMethod } from "../../../../../features/login/login.slice";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { UserService } from "../../../../../services/user.service";
import XMLParser from 'react-xml-parser';

const PaymentModal = (props) => {
    const { paymentDialog, paymentMethods, setPaymentDialog, setPayError, formData, setPayDisabled, totalAmt } = props;
    
    const userSlice = useSelector(state => state.userData);
    const dispatch = useDispatch();
    
    const [paymentMethodState, setPaymentMethodState] = useState("");
    const [paymentSpinner, setPaymentSpinner] = useState(false);
    const [paymentDisabled, setPaymentDisabled] = useState(false);


    const choosePayment = (methodId) => {
        setPaymentMethodState(methodId);
    }

    const paymentCancelAction = () => {
        setPaymentMethodState("");
        props.paymentCancelAction()
    }

    const { mutate: payNowAppointmentMutation } = useMutation(UserService.addAppointment, {
        onSuccess: (data) => {
            var xml = new XMLParser().parseFromString(data.data.incadea);
            const incadeaId = xml.children[0].children[0].children[0].value;
            if (incadeaId.includes("Success-")) {
                const obj = {
                    methodId: paymentMethodState,
                    appointmentId: data.data.data._id
                }
                dispatch(setSelectedAppointmentId(data.data.data._id));
                dispatch(setIncadeaAppointmentId(incadeaId));
                createInvoiceMutation(obj)
            } else {
                setPaymentDisabled(false)
                setPaymentSpinner(false)
                setPaymentDialog(false);
                setPayError("An error occured, please try again");
            }

        },
        onError: (error) => {
            console.log(error);
            setPaymentDisabled(false)
            setPaymentDialog(false);
            setPaymentSpinner(false);
            setPayError(error.response.data.message);
        }
    });

    const { mutate: createInvoiceMutation } = useMutation(UserService.createInvoice, {
        onSuccess: (data) => {
            dispatch(setSelectedPaymentMethod(""));
            window.location.href = data.data.Data.PaymentURL
        },
        onError: (error) => {
            console.log(error);
            dispatch(setSelectedPaymentMethod(""));
            setPaymentSpinner(false);
            setPaymentDialog(false);
            setPayError("An error occured, please try again")
            setPayDisabled(false);
        }
    });

    const createInvoice = () => {
        const methodID = paymentMethodState;
        dispatch(setSelectedPaymentMethod(methodID));
        setPaymentSpinner(true)
        setPaymentDisabled(true)
        const currentVehicle = userSlice.cars.find((val) => val.vehicleNumber === formData.vehicleNumber);
        const reqObj = {
            appointmentDate: formData.date,
            appointmentTime: formData.time,
            appointmentEndTime: formData.endtime,
            customerNumber: userSlice.phone,
            locationCode: formData.location,
            vehicleNumber: formData.vehicleNumber,
            customerId: userSlice.id,
            customerName: userSlice.name,
            services: formData.services,
            amount: totalAmt,
            comments: formData.comments,
            paylater: false,
            vin: currentVehicle.vin
        }

        payNowAppointmentMutation(reqObj)
    }

    return (
        <Modal
            open={paymentDialog}
            onClose={paymentCancelAction}
        >
            <ModalDialog>
                {(paymentSpinner) ?
                    <div>
                        <CircularProgress
                            color="primary"
                            determinate={false}
                            size="md"
                            variant="soft"
                        />
                    </div>
                    :
                    <div className="dialog-content payment-dialog">
                        <p>
                            Choose a payment method
                        </p>
                        {(paymentMethods.length !== 0) ?
                            <div className='method-cont'>
                                {paymentMethods.map((data, index) => {
                                    return (
                                        <div
                                            className={(paymentMethodState === data.PaymentMethodId) ? 'payment-method active' : "payment-method"}
                                            onClick={(e) => choosePayment(data.PaymentMethodId)}
                                        >
                                            <img src={data.ImageUrl} alt='payment-method' />
                                            <span>{data.PaymentMethodEn}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            : ""}
                        <div className='button-cont'>
                            <Button text='Cancel' variant={'border'} onClick={paymentCancelAction} />
                            <Button text='Pay' variant={'yellow'} onClick={createInvoice} disabled={paymentMethodState === ""} />
                        </div>
                    </div>
                }
            </ModalDialog>
        </Modal>
    )
}

export default PaymentModal;