import { useEffect, useState } from 'react';

import './LoginPage.scss';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import carb from './../../img/Land Cruiser.png';
import logo from './../../img/logo.svg';
import { Popover } from '@mui/material';


const LoginPage = () => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const match = useMatch('/login/choose-car')


    const logoClick = () => {
        navigate('/');
    }

    const handlelogoutClose = () => {
        setAnchorEl(null);
    }

    const handleOpenLogout = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleLogout = () => {
        navigate('/logout');
    }


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <div className='LoginPage'>
            <div className="division">
                <div className='div-block left'> 
                    <div className='logo'>
                        <img src={logo} onClick={logoClick} alt='logo'/>
                    </div>
                    <div className='form--login'>
                        <Outlet />
                    </div>
                </div>
                <div className='div-block right'>
                    {(match) ?
                        <div className='logout-Menu' aria-describedby={id} onClick={handleOpenLogout}>
                            <div className='menu-white'>
                                <div className='menu-user'>
                                    <PersonOutlineIcon />
                                </div>
                                <div className='menu-icon'>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                        </div>
                        : ""}
                    <img src={carb} className='carb' alt='black car' />
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlelogoutClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className='popover-logout'>
                    <li onClick={handleLogout}>Logout</li>
                </div>
            </Popover>
        </div>
    )
}

export default LoginPage;