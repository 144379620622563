// Import the necessary dependencies
import PropTypes from 'prop-types';

// Import the CSS styles for the InputBox component
import './InputBox.scss';

// Define a functional component called "InputBox" that accepts props
const InputBox = (props) => {

    // Event handler for input changes
    const handleChange = (e) => {
        if ('onChange' in props) {
            if (props.type === "number") {
                const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                props.onChange(sanitizedValue);
            } else {
                props.onChange(e.target.value);
            }
        }
    }

    const handleKeyDown = (e)=> {
        if ('onKeyDown' in props) {
            props.onKeyDown(e);
        }
    }

    const classNames = "InputBox " + (props.error ? "error" : "");

    return (
        <div className={classNames}>
            <span className='label'>{props.label}</span>
            {(props.type === "number") ? (
                <>
                    <input onKeyDown={handleKeyDown} type={props.type} inputmode="numeric" pattern="[0-9]*" onChange={handleChange} autofill="off" autoComplete="one-time-code" value={props.value} placeholder={props.placeholder} />
                </>
            ) : (
                <input type={props.type} onKeyDown={handleKeyDown} onChange={handleChange} autofill="off" autoComplete="one-time-code" value={props.value} placeholder={props.placeholder} />
            )}
            {(props.error) ? (
                <span className='error'>{props.error}</span>
            ) : (
                ""
            )}
        </div>
    )
}

// Define default prop values for the InputBox component
InputBox.defaultProps = {
    label: "Label",                  // Default label text is "Label"
    placeholder: "Enter your text",  // Default placeholder text
    type: "text"                     // Default input type is "text"
}

// Define the expected prop types for the InputBox component
InputBox.propTypes = {
    label: PropTypes.string.isRequired, // Requires a string for "label" prop
    type: PropTypes.string,            // "type" is a string
    placeholder: PropTypes.string.isRequired, // Requires a string for "placeholder" prop
    onChange: PropTypes.func,          // "onChange" should be a function
    value: PropTypes.string,           // "value" is a string
    onKeyDown: PropTypes.func,
    error: PropTypes.string            // "error" is a string
}

// Export the InputBox component as the default export
export default InputBox;