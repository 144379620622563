import { useEffect, useState } from "react";

function Countdown({ initialTime, onComplete }) {
    const [timeRemaining, setTimeRemaining] = useState(initialTime);

    useEffect(() => {
        let interval;

        if (timeRemaining > 0) {
            interval = setInterval(() => {
                setTimeRemaining(timeRemaining - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            if (onComplete) {
                onComplete();
            }
        }

        return () => {
            clearInterval(interval);
        };
    }, [timeRemaining, onComplete]);

    return (
        <span className="time">{timeRemaining}s</span>
    );
}

export default Countdown;