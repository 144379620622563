import './errorPage.scss';
import logo from './../../img/logo.svg';

const ErrorPage = () => {

    return (
        <div className='ErrorPage'>
            <div>
                <img src={logo} alt='logo' />
            </div>
            <h4>Something went wrong</h4>
            <p>Please contact administrator</p>
        </div>
    )
}

export default ErrorPage;