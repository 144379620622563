
import CircularProgress from '@mui/joy/CircularProgress';
import Input from '@mui/joy/Input';
import DiscountIcon from '@mui/icons-material/Discount';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import BookingOverview from './bookingOverview';
import { Modal, ModalDialog, Tooltip } from '@mui/joy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import XMLParser from 'react-xml-parser';

import Button from '../../../../components/Button/Button';
import { useMutation } from 'react-query';
import { UserService } from '../../../../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { setIncadeaAppointmentId, setSelectedAppointmentId, setSelectedPaymentMethod, setVoucher } from '../../../../features/login/login.slice';
import { useState } from 'react';
import { useEffect } from 'react';
import validator from "validator";
import { clearServices } from '../../../../features/service/service.slice';
import ProgressModal from './components/ProgressModal';
import BookingModal from './components/BookingModal';
import PaymentModal from './components/PaymentModal';

const BookingDetails = (props) => {

    const userSlice = useSelector(state => state.userData);
    const dispatch = useDispatch();
    const selectedCar = userSlice.cars.find((val) => val.vehicleNumber === userSlice.selectedLicense);
    const serviceSlice = useSelector(state => state.serviceData);
    const dayVouchers = userSlice.dayVouchers;
    const [originalTotalAmt, setOriginalTotalAmt] = useState(0);

    const [payDisabled, setPayDisabled] = useState(true);
    const [payNowDisabled, setpayNowDisabled] = useState(false);
    const [paylaterProgress, setPaylaterProgress] = useState(false);
    const [promoDisabled, setPromoDisabled] = useState(false);
    const [showPromoInput, setShowPromoInput] = useState(true);
    const [promoError, setPromoError] = useState("");
    const [incadeaBookingID, setIncadeaBookingID] = useState("");
    const [promoCodeSuccess, setPromoCodeSuccess] = useState("");
    const [confirmDialoglaterOpen, setConfirmDialoglaterOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [voucherCode, setVoucherCode] = useState("");
    const [servicesTotal, setServicesTotal] = useState("");

    const [paymentDialog, setPaymentDialog] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [totalAmt, setTotalAmt] = useState(true);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [payError, setPayError] = useState(false);

    const formData = props.formData;

    useEffect(() => {
        console.log(formData)
        const isFormValid = Object.keys(formData).every((key) => {

            var value = formData[key];
            if (value === null || value === undefined) {
                return false;
            }
            if (Array.isArray(value)) {
                return value.length > 0; // Check if the array is not empty
            } else if (typeof value === 'object' && Object.keys(value).length > 0) {
                return true;
            } else if (typeof value === 'object' && Object.keys(value).length === 0) {
                return false;
            } else if (key === "comments") {
                return true;
            } else if (key === "voucherCode") {
                return true;
            } else {
                return !validator.isEmpty(value); // Check if non-array values are not empty
            }
        });
        if (isFormValid) {
            setPayDisabled(false);

            const currentVehicle = userSlice.cars.find((val) => val.vehicleNumber === formData.vehicleNumber);
            console.log("currentVehicle.vin", currentVehicle.vin);
            if (JSON.stringify(currentVehicle.vin).length<3) {
                setpayNowDisabled(true);
            }
        } else {
            setPayDisabled(true)
            setpayNowDisabled(false);
            
        }
        console.log(formData)
       

        handleCouponRemove()
        if (formData.voucherCode !== "") {
            setVoucherCode(formData.voucherCode)
            voucherCheck(formData.voucherCode)
        }
    }, [formData])

    const { mutate: appointmentMutation } = useMutation(UserService.addAppointment, {
        onSuccess: (data) => {
            var xml = new XMLParser().parseFromString(data.data.incadea);
            const incadeaId = xml.children[0].children[0].children[0].value;
            setIncadeaBookingID(incadeaId)
            setPaylaterProgress(false)
            setSuccessDialogOpen(true)
            dispatch(clearServices())
        },
        onError: (error) => {
            console.log(error);
            setPaylaterProgress(false)
            setPayError(error.response.data.message);
        }
    });



    const { mutate: initiatePaymentMutation } = useMutation(UserService.initiatePayment, {
        onSuccess: (data) => {
            setPaymentMethods(data.data.Data.PaymentMethods)
            setPaymentDialog(true);

        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        let totalAmount = 0;
        const selectedServicesA = serviceSlice.selectedServices;
        // Iterate through each service array
        for (const serviceName in selectedServicesA) {
            if (selectedServicesA.hasOwnProperty(serviceName)) {
                const serviceArray = selectedServicesA[serviceName];

                // Iterate through the sub-services in the array and add up the prices
                for (const subService of serviceArray) {
                    totalAmount += subService.price;
                }
            }
        }
        const roundedNumber = formatToTwoDecimalPlaces(Math.round(totalAmount * 100) / 100);
        console.log(roundedNumber.toFixed(2))
        setTotalAmt(roundedNumber.toFixed(2));
        setOriginalTotalAmt(roundedNumber.toFixed(2)); // Set the original total amount here

        setServicesTotal(roundedNumber.toFixed(2));
        setShowPromoInput(true);
        setPromoCodeSuccess("")
        setVoucherCode("")
    }, [serviceSlice])


    const voucherCheck = (code) => {
        setPromoDisabled(true)
        console.log(voucherCode)
        console.log(dayVouchers)
        console.log(formData.date)
        const dayWeek = moment(formData.date).format("dddd");
        const myCode = (code == null || code == undefined) ? voucherCode : code;

        const voucherInWeek = Object.values(dayVouchers).findIndex((val) => val == myCode);
        console.log(voucherInWeek)
        const obj = {
            id: myCode
        }
        if(voucherInWeek!==-1){
            const voucherDay = Object.keys(dayVouchers)[voucherInWeek];
            console.log(voucherDay);
            if(dayWeek!==voucherDay){
                console.log("NOT APPLICABLE");
                setPromoError("Promo code cannot be applied");
                setPromoDisabled(false)
            }else{
                voucherCheckMutation(obj)
            }
        }else{
            voucherCheckMutation(obj)
        }
        
    }

    const handleCouponRemove = () => {
        setVoucherCode("");
        setPromoCodeSuccess("");
        setTotalAmt(formatToTwoDecimalPlaces(parseInt(servicesTotal)));
        dispatch(setVoucher({
            vouchercode: "",
            voucheramt: 0
        }))
        setShowPromoInput(true);
    }


    function formatToTwoDecimalPlaces(number) {
        if (typeof number !== 'number') {
            return NaN; // Return NaN if the input is not a number
        }
        return parseFloat(number.toFixed(2));
    }

    const { mutate: voucherCheckMutation } = useMutation(UserService.checkVoucher, {
        onSuccess: (data) => {
            handleCouponRemove();
            setTotalAmt(formatToTwoDecimalPlaces(parseInt(servicesTotal)));
            const percent = data.data.data.percentage;
            const amt = formatToTwoDecimalPlaces(formatToTwoDecimalPlaces(parseInt(servicesTotal)) * percent / 100);
            const finalAmt = formatToTwoDecimalPlaces(parseInt(servicesTotal)) - amt;
            setPromoCodeSuccess("Applied promo code. You saved QAR " + amt.toFixed(2));
            const roundedNumber = Math.round(finalAmt * 100) / 100;
            setTotalAmt(roundedNumber.toFixed(2));
            setPromoDisabled(false);
            dispatch(setVoucher({
                vouchercode: voucherCode,
                voucheramt: amt
            }))
            setShowPromoInput(false);
        },
        onError: (error) => {
            console.log(error);
            setPromoError(error.response.data.message);
            setPromoDisabled(false)
        }
    });


    const payLater = () => {
        setConfirmDialoglaterOpen(true); // Open the confirmation dialog
    };
    const handleConfirmPaylater = () => {
        setPayError(false);
        setPaylaterProgress(true);
        const currentVehicle = userSlice.cars.find((val) => val.vehicleNumber === formData.vehicleNumber);
        let reqObj = {
            appointmentDate: formData.date,
            appointmentTime: formData.time,
            appointmentEndTime: formData.endtime,
            customerNumber: userSlice.phone.replace("+974", ""),
            locationCode: formData.location,
            vehicleNumber: formData.vehicleNumber,
            customerId: userSlice.id,
            customerName: userSlice.name,
            services: formData.services,
            amount: totalAmt,
            comments: formData.comments,
            paylater: true,
            vin: currentVehicle.vin
        }
        console.log('appointmentMutation' , reqObj);
         appointmentMutation(reqObj);
    };

    // const payLater = () => {
    //     setPayError(false);
    //     setPaylaterProgress(true);
    //     const currentVehicle = userSlice.cars.find((val) => val.vehicleNumber === formData.vehicleNumber);
    //     let reqObj = {
    //         appointmentDate: formData.date,
    //         appointmentTime: formData.time,
    //         appointmentEndTime: formData.endtime,
    //         customerNumber: userSlice.phone.replace("+974", ""),
    //         locationCode: formData.location,
    //         vehicleNumber: formData.vehicleNumber,
    //         customerId: userSlice.id,
    //         customerName: userSlice.name,
    //         services: formData.services,
    //         amount: totalAmt,
    //         comments: formData.comments,
    //         paylater: true,
    //         vin: currentVehicle.vin
    //     }
    //     appointmentMutation(reqObj);
    // }

    // const payNow = () => {
    //     setPayError(false);
    //     setPayDisabled(true);
    //     initiatePaymentMutation(totalAmt);
    // }
    const payNow = () => {
        setConfirmDialogOpen(true); // Open the confirmation dialog
    };
    const handleConfirmPayNow = () => {
        setPayError(false);
        setPayDisabled(true);
        initiatePaymentMutation(totalAmt);
        console.log('initiatePaymentMutation' , totalAmt);
        setConfirmDialogOpen(false); // Close the dialog after confirming
    };
    const paymentCancelAction = () => {
        setPayDisabled(false);
        setPaymentDialog(false);

    }

    const handlerPromoCode = (e) => {
        setVoucherCode(e.target.value);
        setPromoError("");
    }




    const voucherKeyDown = (e) => {
        if (e.key === 'Enter' && !(promoDisabled || payDisabled)) {
            voucherCheck();
        }
    }
    return (
        <div>
            <div className='card'>
                <div>
                    Service Booking for
                    <h3>{selectedCar.carBrand} {selectedCar.model} - {selectedCar.year}, {selectedCar.vehicleNumber}</h3>
                </div>
                <BookingOverview services={serviceSlice.selectedServices} />
                <div className='coupon-cont'>
                    {(showPromoInput) ?
                        <div className='coupon'>
                            <Input className='promo-input' placeholder='Enter promo code' autofill="off" autoComplete="one-time-code"
                                onChange={(e) => handlerPromoCode(e)} id="outlined-basic" label="Enter promo code" variant="outlined"
                                value={voucherCode}
                                startDecorator={<DiscountIcon />}
                                onKeyDown={voucherKeyDown}
                            />
                            <div>
                                <Button text='Apply' onClick={voucherCheck} disabled={promoDisabled || payDisabled} />
                            </div>
                        </div>
                        : <div className='coupon-applied'>
                            <div className='left-side'> <LocalOfferIcon /> <span>Coupon applied : {voucherCode}</span></div>
                            <div onClick={handleCouponRemove}>
                                <DeleteOutlineIcon />
                            </div>
                        </div>
                    }

                    {(promoError !== "") ? <span className='error'>{promoError}</span> : ""}
                    {(promoCodeSuccess !== "") ? <span className='success'>{promoCodeSuccess}</span> : ""}
                </div>
                <div className='estimate'>
                    <span className='label'>Estimated Amount </span>
                    <span className='icon'>
                        <Tooltip title="The actual cost can be subject to the service advisor's evaluation." size="md">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </span>
                    <span className='right'> QAR {totalAmt}</span>
                </div>
                <div>
                    {(payError) ?
                        <div className='error'>{payError}</div>
                        : ""}
                </div>
                <div className='button-cont-serv'>
                    <Button variant={'border'} text={'Pay later - QAR ' + formatToTwoDecimalPlaces(parseInt(servicesTotal))} onClick={payLater} disabled={payDisabled} />
                    <Button variant={'yellow'} text={'Pay now - QAR ' + totalAmt} onClick={payNow}
                        disabled={payDisabled || payNowDisabled}
                    />
                </div>

            </div>
            <div className='mobile-button-cont-serv'>
                <Button variant={'border'} text={'Pay later - QAR ' + formatToTwoDecimalPlaces(parseInt(servicesTotal))} onClick={payLater} disabled={payDisabled} />
                <Button variant={'yellow'} text={'Pay now - QAR ' + totalAmt} onClick={payNow}
                    disabled={payDisabled || payNowDisabled}
                />
            </div>
{payNowDisabled && (
    <div className='info-message'>
    <p className='bold-message'>Your vehicle is not yet registered!</p>
        <p>Enjoy exclusive discounts by registering your vehicle with Autolab. Haven't registered yet? Simply give our hotline a call at 8000803</p>
    </div>
)}

            <BookingModal successDialogOpen={successDialogOpen} incadeaBookingID={incadeaBookingID} />

            <ProgressModal showProgress={paylaterProgress} />
            <Dialog
    open={confirmDialogOpen}
    onClose={() => setConfirmDialogOpen(false)}
    aria-labelledby="confirm-dialog-title"
    aria-describedby="confirm-dialog-description"
>
    <DialogTitle id="confirm-dialog-title"> By clicking the "Pay now" button, I am agreeing to the following terms:</DialogTitle>
    <DialogContent>
        

        <DialogContentText id="confirm-dialog-later-description">
    <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>  {/* Inline styles for demonstration */}
        <li>The amount is non-refundable. However, I can amend the booking schedule in case of any unlikely situation.</li>
        <li>Any additional services will be payable at Autolab Quick Service.</li>
    </ul>
</DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button text="Dismiss" onClick={() => setConfirmDialogOpen(false)} variant="border">Dismiss</Button>
        <Button text="Agree & Pay Now" onClick={handleConfirmPayNow} color="primary" autoFocus>
            Agree & Pay Now
        </Button>
    </DialogActions>
</Dialog>
<Dialog
    open={confirmDialoglaterOpen}
    onClose={() => setConfirmDialoglaterOpen(false)}
    aria-labelledby="confirm-dialog-later-title"
    aria-describedby="confirm-dialog-later-description"
>
    <DialogTitle id="confirm-dialog-later-title">
    By choosing to 'Pay later', I understand that:
    </DialogTitle>
    <DialogContent>
       

        <DialogContentText id="confirm-dialog-later-description">
    <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>  {/* Inline styles for demonstration */}
        <li>The amount is subject to adjustments based on the final services rendered.</li>
        <li>Any additional services will be payable at Autolab Quick Service.</li>
    </ul>
</DialogContentText>

    </DialogContent>
    <DialogActions>
        <Button text="Dismiss" onClick={() => setConfirmDialoglaterOpen(false)} variant="border">Dismiss</Button>
        <Button text="Agree & Pay Later" onClick={handleConfirmPaylater} color="primary" autoFocus>
            Agree & Pay Later
        </Button>
    </DialogActions>
</Dialog>



            <PaymentModal
                paymentDialog={paymentDialog}
                setPaymentDialog={setPaymentDialog}
                paymentCancelAction={paymentCancelAction}
                paymentMethods={paymentMethods}
                formData={formData}
                totalAmt={totalAmt}
                setPayError={setPayError}
                setPayDisabled={setPayDisabled}
            />

        </div>
    )
}

export default BookingDetails;