
import sedan from './../../../../img/sedan.svg';
import bigsuv from './../../../../img/Big SUV.svg';
import suv from './../../../../img/SUV.svg';

const EngineSizeComp = (props) => {
    const { data, defaultValue, disabled, onChange } = props;

    const handleClick = (enginesize) => {
        if(!disabled){
            onChange(enginesize)
        }
    }
    return (
        <div className={(disabled) ? "enginesize-comp disabled" : "enginesize-comp"}>
            {data.map((data, index) => {
                var img = "";
                var classname = "single-engine";
                switch (data.engineSize) {
                    case "Upto 1.5 ltr": img = sedan; break;
                    case "1.5 ltr to 2.5 ltr": img = suv; break;
                    case "2.5 ltr and above": img = bigsuv; break;
                }
                if (defaultValue === data.engineSize) {
                    classname += " active";
                }
                return (
                    <div className={classname} onClick={(e) => handleClick(data.engineSize)}>
                        <div className='img-cont'>
                            <img src={img} alt='engine size' />
                        </div>
                        <span>
                            {data.engineSize}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

export default EngineSizeComp;