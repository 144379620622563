import './HomePage.scss';

import Button from "../../components/Button/Button";
import { useNavigate } from 'react-router-dom';

import logo from './../../img/logo.svg';
import car1 from './../../img/car1.png';

import { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuickBookModal from './components/QuickbookModal';

const HomePage = (props) => {
    // Import necessary hooks and functions
    const navigate = useNavigate();

    // Initialize state variable for the quick booking modal
    const [quickOpen, setQuickOpen] = useState(false);

    // Function to open the quick booking modal
    const quickBook = () => {
        setQuickOpen(true);
    }

    useEffect(()=> {
        document.title = 'AUTOLAB Qatar | Book Car Servicing';
    }, [])

    // Function to navigate back to a web page
    const backtoweb = () => {
        window.location.href = "https://auto-lab.com/#";
    }


    return (
        <div className='HomePage'>
            <div className='header'>
                <div className='logo'>
                    <img src={logo} alt='logo' />
                </div>

            </div>
            <div className="division">
                <div className='div-block left'>
                    <div className="backtoweb" onClick={backtoweb}>
                        <ArrowBackIcon /> Back to website
                    </div>
                    <img className='carmobile' src={car1} alt='red car' />
                    <h3 className='heading'>Experience the Best Car Service</h3>
                    <p>
                        Find the best car service packages for your car. Select from a wide range of car repairs & services.
                    </p>

                    <div className='button-cont'>
                        <Button text='Book Service' onClick={quickBook} />
                        <Button text='Login' variant={'border'} onClick={(e) => navigate('/login')} />

                    </div>

                </div>
                <div className='div-block right'>
                    <Button text='Back to website' onClick={backtoweb} variant={'border'} />
                    <img className='cara' src={car1} alt='red car' />
                </div>
            </div>
            <QuickBookModal quickOpen={quickOpen} setQuickOpen={setQuickOpen} />
        </div>
    )
}

export default HomePage;