import { Modal, ModalDialog } from "@mui/joy";
import Button from "../../../../components/Button/Button";
import { useMutation, useQueryClient } from "react-query";
import { UserService } from "../../../../services/user.service";


const DeleteCarModal = (props) => {

    const queryClient = useQueryClient()

    const deleteCarConfirm = () => {
        const obj = {
            id: props.deleteId
        }
        deleteCarMutation(obj);
    }
    const { mutate: deleteCarMutation } = useMutation(UserService.deleteCar, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['getUser'] })
            props.setDeleteDialogOpen(false);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    return (
        <Modal
            open={props.deleteDialogOpen}
            onClose={props.deleteDialogClose}
        >
            <ModalDialog>
                <div className="dialog-delete">
                    <h3>Delete Car</h3>
                    <p>Are you sure you want to delete?</p>
                    <div className="button-cont">
                        <Button text='Cancel' variant={'border'} onClick={props.deleteDialogClose} />
                        <Button text='Delete' onClick={deleteCarConfirm} />

                    </div>
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default DeleteCarModal;