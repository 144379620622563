import { useState } from "react";
import BookingDetails from "./BookingDetails";
import ServiceDetails from "./service-details";
import { useSelector } from "react-redux";

import './service-details.scss';
import { useEffect } from "react";
import validator from "validator";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

const ServiceParent = () => {
    const userSlice = useSelector(state => state.userData);
    const navigate = useNavigate();

    const [showBooking, setShowBooking] = useState(false);
    const [payDisabled, setPayDisabled] = useState(false);
    const [formData, setFormData] = useState({
        vehicleNumber: userSlice.selectedLicense,
        services: [],
        location: "",
        date: "",
        time: "",
        endtime: "",
        comments: "",
        voucherCode: ""
    });

    // useEffect(() => {
    //     const isFormValid = Object.keys(formData).every((key) => {

    //         var value = formData[key];
    //         if (value === null || value === undefined) {
    //             return false;
    //         }
    //         if (Array.isArray(value)) {
    //             return value.length > 0; // Check if the array is not empty
    //         } else if (typeof value === 'object' && Object.keys(value).length > 0) {
    //             return true;
    //         } else if (typeof value === 'object' && Object.keys(value).length === 0) {
    //             return false;
    //         } else if (key === "comments") {
    //             return true;
    //         } else if (key === "voucherCode") {
    //             return true;
    //         }
    //         else {
    //             return !validator.isEmpty(value); // Check if non-array values are not empty
    //         }
    //     });
    //     if (isFormValid) {
    //         setPayDisabled(false);
    //     } else {
    //         setPayDisabled(true)
    //     }
    // }, [formData])

    var width = window.innerWidth;

    const setForm = (obj) => {
        setFormData(obj);
    }

    const backMobilePress = () => {
        if (showBooking === false) {
            navigate("/service-request/car")

        } else {
            setShowBooking(false)
        }
    }
    return (
        <div >

            {(width < 600) ?
                <div className="mobile-services">
                    <div className="mobile-navig">
                        <div className="icon-div" onClick={backMobilePress}><ArrowBackIosIcon /></div>
                        <div className="heading">Services</div>
                    </div>
                    <div>
                        <ServiceDetails callback={setForm} />
                        <BookingDetails formData={formData} />
                    </div>

                </div>
                : <div className='main-grid'>
                    <ServiceDetails callback={setForm} />
                    <BookingDetails formData={formData} />
                </div>}

        </div>
    )
}

export default ServiceParent;