import { CircularProgress, Modal, ModalDialog } from "@mui/joy";


const ProgressModal = (props) => {
    return (
        <Modal
            open={props.showProgress}
        >
            <ModalDialog>
                <div className="dialog-content completed-dialog">
                    <CircularProgress
                        color="primary"
                        determinate={false}
                        size="md"
                        variant="soft"
                    />
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default ProgressModal;