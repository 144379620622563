import './noMatch.scss';
import logo from './../../img/logo.svg';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

const NoMatch = () => {
    const navigate = useNavigate();
    const buttonPress = () => {
        navigate('/');
    }
    return (
        <div className='ErrorPage'>
            <div>
                <img src={logo} alt='logo' />
            </div>
            <h4>Page not found</h4>
            <p>The page you are looking does not exist.</p>
            <Button text='Back to homepage' onClick={buttonPress}/>
        </div>
    )
}

export default NoMatch;