import { Modal, ModalClose, ModalDialog } from "@mui/joy"
import Button from "../../../components/Button/Button"
import { useDispatch } from "react-redux";
import { forwardRef, useRef, useState } from "react";
import { useMutation } from "react-query";
import { AuthService } from "../../../services/auth.service";
import { setQuickBook, setUserData } from "../../../features/login/login.slice";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import validator from "validator";
import { useEffect } from "react";
import { useCallback } from "react";

const QuickBookModal = (props) => {
    const phoneInputRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Initialize state variables for form data, errors, and button state
    const [formData, setFormData] = useState({
        phone: "",
    });
    const [errors, setErrors] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [phoneVal, setPhoneVal] = useState("");

    // Destructure values from the useMutation hook for the login process
    const { mutate: loginMutation } = useMutation(AuthService.loginPhone, {
        onSuccess: (data) => {
            // On successful login, update user data and navigate to OTP verification
            const userData = {
                name: '',
                email: '',
                phoneNumber: formData.phone,
                _id: data.data.data
            }
            dispatch(setUserData(userData));
            navigate('/login/otp');
        },
        onError: (error) => {
            // Handle login error
            console.log(error);
        }
    });

    // Function to initiate the booking process
    const bookPress = () => {
        dispatch(setQuickBook(true));
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        const noErrorsFound = Object.values(errors).length === 1 && Object.values(errors).every((value) => validator.isEmpty(value));
        if (isFormValid && noErrorsFound) {
            // If the form is valid and there are no errors, initiate the login process
            loginMutation(formData);
        } else {
            // Handle form validation errors
            console.log("error");
        }
    }

    // Function to validate a form field
    const validateField = (name, value) => {
        if (value === undefined) {
            value = "";
        }
        let error = "";
        if (name === "phone" && validator.isEmpty(value)) {
            error = "Maximum number reached";
        }
        return error;
    };

    // Function to handle changes in form fields
    const handleChange = (name, value) => {
        console.log(name, value)
        if (value === undefined) {
            value = "";
            setBtnDisabled(true);
        }else if ( value.length >= 11  && value.includes("+974")) {
            const error = validateField(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        if (isFormValid ) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
        } else {
            setBtnDisabled(true);
        }
        
        
    };

    const handlePhoneInput = (e) => {
        handleChange("phone", e)
    }

    useEffect(() => {
        const element = document.getElementsByClassName('PhoneInputInput')[0];
        if (element) {
            setTimeout(() => {
                element.focus();
            }, 100);
        }
    }, [formData]);

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !btnDisabled) {
            bookPress();
        }
    }

    const InputComponent = forwardRef((props, ref) => <input {...props} ref={ref} onKeyDown={onKeyDown} keyboardDismissMode="none" autoComplete="off" />)


    return (
        <Modal
            open={props.quickOpen}
            // disableEscapeKeyDown={true}
            onClose={() => props.setQuickOpen(false)}
        >
            <ModalDialog>
                <ModalClose onClick={() => props.setQuickOpen(false)} />
                <h3>Quick Book</h3>
                <PhoneInput
                keyboardDismissMode="none"  
                    placeholder="Enter phone number"
                    value={(formData.phone) ? formData.phone : ""}
                    countries={['QA']}
                    defaultCountry={"QA"}
                    addInternationalOption={false}
                    inputComponent={InputComponent}
                    international="false"
                    onChange={handlePhoneInput} />

                <div className='button-book-cont'>
                    <Button text='Quick book' disabled={btnDisabled} onClick={bookPress} />
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default QuickBookModal;