
import InputBox from "../../../components/InputBox/InputBox";
import { useEffect, useState } from "react";

import validator from "validator";
import Button from "../../../components/Button/Button";
import { useMutation } from "react-query";
import { AuthService } from "../../../services/auth.service";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../features/login/login.slice";
import { useNavigate } from "react-router-dom";

const PhoneLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        phone: ""
    });
    const [errors, setErrors] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [formError, setFormError] = useState("");

    const { mutate: loginMutation, data: loginSuccess, error: loginError } = useMutation(AuthService.loginPhone, {
        onSuccess: (data) => {
            const userData = {
                name: '',
                email: '',
                phoneNumber: formData.phone,
                _id: data.data.data
            }
            dispatch(setUserData(userData))
            navigate('/login/otp');
            
        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        if (loginError) {
            setFormError(loginError.response.data.message);
        } else {
            setFormError("");
        }
    }, [loginError])

    const validateField = (name, value) => {
        let error = "";
        if (name === "phone" && !validator.isMobilePhone(value)) {
            error = "Invalid Phone";
        }
        return error;
    };

    const handleChange = (name, value) => {
        setFormError("");
        const error = validateField(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        if (isFormValid && errors.phone === "") {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true)
        }
    };

    const loginHandler = () => {
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        const noErrorsFound = Object.values(errors).length === 1 && Object.values(errors).every((value) => validator.isEmpty(value));
        if (isFormValid && noErrorsFound) {
            loginMutation(formData)
        } else {
            console.log("error")
        }
    };


    return (
        <div>
            <h3>Welcome</h3>
            <InputBox label='Phone' placeholder="Enter your phone" value={formData.phone} onChange={(value) => handleChange("phone", value)} error={errors.phone} />
            {(formError === "") ? "" : <span className="error">{formError}</span>}
            <Button text='Login' onClick={loginHandler} disabled={btnDisabled} />
        </div>
    )
}

export default PhoneLogin;