import { useDispatch } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteService } from '../../../../../features/service/service.slice';

const BookingItem = (props) => {

    const dispatch = useDispatch();
    const deleteItem = (heading, subserv) => {
        dispatch(deleteService({
            mainService: { serviceName: heading },
            subService: subserv
        }))
    }

    let totalPrice = 0;
    props.value.forEach(subService => {
        totalPrice += subService.price;
    });
    return (
        <div className='booking-item'>
            <div className='price-cont'>
                <span className='category'>{props.heading}</span>
                <span className='total'>QAR {totalPrice}</span>
            </div>
            {props.value.map((value, index) => {
                return (
                    <div className='sub-category'>
                        <span>{value.subService} - QAR {value.price}</span>
                        {(props.heading !== "Additional services") ?
                            <span className='delete-item' onClick={(e) => deleteItem(props.heading, value)}>
                                <DeleteOutlineIcon />
                            </span>
                            : ""}
                    </div>
                )
            })}
        </div>
    )
}

export default BookingItem;