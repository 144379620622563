import { Checkbox, InputLabel, Popover, TextField } from '@mui/material';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ServiceTabs from './serviceTabs';
import { useMutation, useQuery } from 'react-query';
import { UserService } from '../../../../services/user.service';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import XMLParser from 'react-xml-parser';
import dateTimeJSON from '../../../../helpers/datetime.xml';
import moment from 'moment';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';


import { addService, clearServices, deleteService } from '../../../../features/service/service.slice';
import ProgressModal from './components/ProgressModal';
import DesktopServiceTabs from './components/DesktopServiceTabs';
import { setDayVouchers } from '../../../../features/login/login.slice';



const ServiceDetails = (props) => {

    const userSlice = useSelector(state => state.userData);
    const dispatch = useDispatch();
    const selectedCar = userSlice.cars.find((val) => val.vehicleNumber === userSlice.selectedLicense);
    const serviceSlice = useSelector(state => state.serviceData);

    const { isLoading: dataLoading, data: servicesData, error: serviceError } = useQuery('getServicesDrop', UserService.getServices);
    const [checkedStates, setCheckedStates] = useState({});

    const [allowedDates, setAllowedDates] = useState([]);
    const [formError, setFormError] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [calendarData, setCalendarData] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [payError, setPayError] = useState(false);
    const [showProgress, setShowProgress] = useState(false);

    var width = window.innerWidth;

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        vehicleNumber: userSlice.selectedLicense,
        services: [],
        location: "",
        date: "",
        time: "",
        endtime: "",
        comments: "",
        voucherCode: ""
    });
    useEffect(() => {
        if (dataLoading === false && servicesData === undefined) {
            navigate("/logout")
        } else if (servicesData !== undefined) {
            dispatch(setDayVouchers(servicesData.data.voucherCode))
        } else {

        }
    }, [dataLoading, serviceError, servicesData])

    const { mutate: calendarMutation } = useMutation(UserService.getCalendar, {
        onSuccess: (data) => {
            console.log(data);

            setCalendarData(data);
            setShowProgress(false);
        },
        onError: (error) => {
            setShowProgress(false);
            console.log(error);
        }
    });

    useEffect(() => {
        props.callback(formData);
    }, [formData])

    const handleChange = (name, value) => {
        setFormError("");
        setPayError(false);
        setFormData((prevState) => ({ ...prevState, [name]: value }));



    };

    const locationChange = (e, newItem) => {
        if (newItem !== null) {
            handleChange("location", newItem)
            handleChange("date", "")
            handleChange("time", "")
            setSelectedDate(null)
            setShowProgress(true);
            calendarMutation(newItem)

        }
    }

    useEffect(() => {
        handleChange("services", serviceSlice.selectedServices)
    }, [serviceSlice.selectedServices])

    const handleServiceChange = (data) => {
    }

    useEffect(() => {
        if (userSlice.selectedLicense === "" || userSlice.selectedLicense === undefined) {
            navigate("/login/choose-car");
        }
        document.title = 'AUTOLAB Qatar | Service Request';
    }, []);


    useEffect(() => {
        if (calendarData !== undefined && calendarData !== null) {
            var xml = new XMLParser().parseFromString(calendarData.data);
            const timeval = dateTimeJSON(xml);
            console.log(timeval)
            setAllowedDates(timeval)
            if (Object.keys(timeval).length > 0) {
                const dateKey = moment(Object.keys(timeval)[0]);
                setSelectedDate(dateKey);
                const currday = dateKey.format("dddd")
                const voucherCode = servicesData.data.voucherCode[currday];
                handleChange("voucherCode", (voucherCode == null) ? "" : voucherCode)
                handleChange("date", dateKey.format("YYYY-MM-DD"))

                if (timeval[dateKey.format("YYYY-MM-DD")] !== undefined  && Object.keys(timeval[dateKey.format("YYYY-MM-DD")][0]).length !== 0   ) {
                    handleChange("time", timeval[dateKey.format("YYYY-MM-DD")][0].startDate)
                    handleChange("endtime", timeval[dateKey.format("YYYY-MM-DD")][0].endDate)
                    
                }
            }
        }
    }, [calendarData])

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (allowedDates[date.format("YYYY-MM-DD")][0] !== undefined && Object.keys(allowedDates[date.format("YYYY-MM-DD")][0]).length !== 0 ) {
            handleChange("time", allowedDates[date.format("YYYY-MM-DD")][0].startDate)
            handleChange("endtime", allowedDates[date.format("YYYY-MM-DD")][0].endDate)
        }
        const currday = date.format("dddd")
        const voucherCode = servicesData.data.voucherCode[currday];
        handleChange("voucherCode", (voucherCode == null) ? "" : voucherCode)
        handleChange("date", date.format("YYYY-MM-DD"))
    };

    const isDateDisabled = (date) => {
        const dateString = moment(date).format('YYYY-MM-DD');
        if (Object.keys(allowedDates).includes(dateString)) {
            return false
        } else {
            return true
        }
    };




    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const popoverTimeSelect = (e, date, endDate) => {
        handleChange("time", date)
        handleChange("endtime", endDate)
        setAnchorEl(null);
    }

    const handleAddressChange = (event, chargeName) => {
        // Extract the current comments and split them into lines
        let commentsArray = formData.comments.split('\n');
        // Find if there's already an entry for this chargeName
        let existingEntryIndex = commentsArray.findIndex(line => line.startsWith(chargeName + ":"));
        // New entry to add or update, with additional space for clarity
        const newEntry = `${chargeName}: ${event.target.value}  . `;
    
        if (existingEntryIndex !== -1) {
            // Update the existing entry if found
            commentsArray[existingEntryIndex] = newEntry;
        } else {
            // Add a new entry if not found. Check if we need to add a leading newline for separation
            if (commentsArray.length > 0 && commentsArray[0] !== "") {
                commentsArray.push(""); // Add an empty line for spacing between entries
            }
            commentsArray.push(newEntry);
        }
    
        // Filter out any empty lines at the start or end of the comments
        commentsArray = commentsArray.filter((line, index) => {
            // Remove empty lines at the start or end, but keep spacing between entries
            return line.trim() !== "" || (index !== 0 && index !== commentsArray.length - 1);
        });
    
        // Join the array back into a string and set it as the new comments
        const updatedComments = commentsArray.join('\n');
        handleChange("comments", updatedComments);
    };
    
    // const handleAddressChange = (event, chargeName) => {
    //     // Update the address in your state, this is just a placeholder logic
    //     // You would need to update your state structure accordingly
    //     const updatedServices = servicesData.data.additionalCharges.map(charge => {
    //       if (charge.additionalChargeName === chargeName) {
    //         return { ...charge, address: event.target.value };
    //       }
    //       return charge;
    //     });
    //   console.log(updatedServices)
    //     // Assuming you have a method or state updater to modify servicesData
    //     // updateServicesData(updatedServices);
    //   };
      
      
    const handleAdditionalCheck = (e) => {
        const { id, checked } = e.target;
        setCheckedStates(prev => ({
          ...prev,
          [id]: checked
        }));
        if (e.target.checked) {
            const splittedVal = e.target.value.split("|")
            dispatch(addService({
                mainService: { serviceName: "Additional services" },
                subService: {
                    subServiceName: splittedVal[0],
                    price: parseInt(splittedVal[1]),
                    subServiceCode: splittedVal[0],
                }
            }));
        } else {
            const splittedVal = e.target.value.split("|")
            dispatch(deleteService({
                mainService: { serviceName: "Additional services" },
                subService: {
                    subServiceName: splittedVal[0],
                    price: parseInt(splittedVal[1]),
                    subServiceCode: splittedVal[0],
                }
            }));
        }
    }

    const goBack = () => {
        dispatch(clearServices());
        navigate("/service-request/car")
    }

    if (dataLoading === true) {
        return "Loading"
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>


                <div>
                    <div className='card'>
                        <h4 className='heading' onClick={goBack}> <ArrowBackIcon /> Select services <span className='mandatory'> *</span></h4>
                        <div className="dinfo">
    <span>Please select the desired service from the options provided below. </span>
    
</div>
                        {(width < 600) ?
                            <ServiceTabs
                                engineOrigin={selectedCar.engineOrigin}
                                engineSize={selectedCar.engineSize} services={servicesData.data.services} callback={handleServiceChange}
                            />
                            :
                            <DesktopServiceTabs
                                engineOrigin={selectedCar.engineOrigin}
                                engineSize={selectedCar.engineSize} services={servicesData.data.services} callback={handleServiceChange}
                            />
                        }
                    </div>
                    <div className='grid-50'>
                        <div className='card'>
                            <InputLabel id="">Location <span className='mandatory'>*</span></InputLabel>
                            <Select
                             sx={{ fontSize: '0.85rem' }} 
                                id="demo-simple-select"
                                value={formData.location}
                                placeholder="Select location"
                                onChange={locationChange}
                                fullWidth
                            >
                                {servicesData.data.locations.map((value, index) => {
                                    return (
                                        <Option key={index} value={value.locationCode}>{value.locationName}</Option>
                                    )
                                })}

                            </Select>
                        </div>
                        {servicesData.data.additionalCharges && servicesData.data.additionalCharges.length > 0 && (
                        <div className='card additional-services'>
  <InputLabel id="">Additional Services </InputLabel>
  {servicesData.data.additionalCharges.map((data, index) => (
    <div className="service-item" key={data.additionalChargeName}>
      <label htmlFor={data.additionalChargeName} className="checkbox-label">
        <Checkbox
          checked={!!checkedStates[data.additionalChargeName]}
          onChange={handleAdditionalCheck}
          id={data.additionalChargeName}
          value={data.additionalChargeName + "|" + data.additionalCost}
        />
        <span>{data.additionalDescription}</span>
      </label>
      {checkedStates[data.additionalChargeName] && (
        <div className="address-input">
          <input
            type="text"
            placeholder="Enter address"
            onChange={(e) => handleAddressChange(e, data.additionalChargeName)}
            // value={data.address || ''}
          />
        </div>
      )}
    </div>
  ))}
</div>
 )}

                    </div>
                    <div className='card'>
                        <div className='grid-50'>
                            <div>
                                <InputLabel id="">Date <span className='mandatory'>*</span></InputLabel>
                                <DatePicker
                                    label="Enter a date"
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    disabled={!formData.location}
                                    shouldDisableDate={isDateDisabled}
                                />
                                     
                            </div>
                            <div>
                                <InputLabel id="">Time <span className='mandatory'>*</span></InputLabel>

                                <div className='timePick' onClick={(e) => handleClick(e)} aria-describedby={id}>

                                    {(formData.time === "") ? "--:--" : moment(formData.time, "HH:mm:00").format("HH:mm A")}
                                </div>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    {(selectedDate) ?
                                        <div className='popover-time'>
                                            {allowedDates[selectedDate.format("YYYY-MM-DD")].map((data, index) => {
                                                return (
                                                    <div onClick={(e) => popoverTimeSelect(e, data.startDate, data.endDate)}>{moment(data.startDate, "HH:mm:00").format("HH:mm A")}</div>
                                                )
                                            })}
                                            {(allowedDates[selectedDate.format("YYYY-MM-DD")].length === 0) ? "No slots available" : ""}
                                        </div>
                                        : ""}
                                </Popover>
                            </div>
                            
                        </div>
                        
                        <div className="discount-info">
    <span>Enjoy a 10% discount by booking online. Get a special 15% discount for appointments made on Tuesdays and Wednesdays.</span>
    <span> This offer is applicable only for prepaid online bookings.</span>
</div>

                    </div>
                    <div className='card'>
                        <h4>Comment</h4>
                        <TextField id="outlined-basic" fullWidth variant="outlined" value={formData.comments} onChange={(e) => handleChange("comments", e.target.value)} />
                    </div>
                </div>
                <ProgressModal showProgress={showProgress} />

            </LocalizationProvider>
        </div>
    )
}

export default ServiceDetails;