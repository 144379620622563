import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/login/login.slice";
import { persistor, store } from './../../store';
import { queryClient } from "../../AppRouter";
import { clearServices } from "../../features/service/service.slice";

const LogoutPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.clear("token");
        persistor.flush().then(() => {
            return persistor.purge();
        });
        queryClient.invalidateQueries('getUser');
        dispatch(logout());
        dispatch(clearServices());

        navigate("/login/")
    }, []);

    return (
        <div></div>
    )
}

export default LogoutPage;