const AccordianContent = (props) => {
    const { services, index, engineSize, engineOrigin, buyServices } = props;
    const filteredData = services[index].subServices.filter((item) => {
        // Split the code by 'S' to extract the engine origin and size
        var eSize = "";
        var eOrigin = "";
        if (engineSize === "Upto 1.5 ltr") {
            eSize = "1.5";
        } else if (engineSize === "1.5 ltr to 2.5 ltr") {
            eSize = "1.5TO2.5";
        } else {
            eSize = "2.5ABV"
        }
        if (engineOrigin === "Asian") {
            eOrigin = "AS";
        } else if (engineOrigin === "US") {
            eOrigin = "US"
        } else if (engineOrigin === "European") {
            eOrigin = "EU"
        } else {

        }
        var merge = eOrigin + "" + eSize;
        // Check if the engine origin and size match the filter criteria
        return item.subServiceCode.endsWith(merge);
    });

    const isSubServiceSelected = (mainService, subService) => {
        const mainServ = mainService.serviceName;
        const subServ = subService.subServiceName;
        const subServCode = subService.subServiceCode;
        return buyServices[mainServ] && buyServices[mainServ].some(item => item.subServiceCode === subServCode);
    }

    const itemSelect = (serv, subserv, exist) => {
        props.callback(serv, subserv, exist)
    }
    return (
        filteredData.map((subservice, subIndex) => {
            const exist = isSubServiceSelected(services[index], subservice)
            return (
                <div className="tab-sub-cats">
                    <span
                        onClick={() => itemSelect(services[index], subservice, exist)}
                        className={exist ? 'selected' : ''}
                    >
                        {subservice.subServiceName}
                    </span>
                </div>
            )
        })
    )
}

export default AccordianContent;